import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const Input =  props => (
  <Grid item xs={props.xs} sm={props.sm}>
    <TextField
      id={props.id}
      name={props.id}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
    />
  </Grid>
);

export default Input;