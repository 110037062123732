import React from 'react';
import Login from './views/login';
import Rx from './views/rx';
import PdfDoc from './views/pdfdoc';

import { Switch, Route} from 'react-router-dom';

const Router = ()=>(
  <Switch>
    <Route path="/"  component={Login} exact/>
    <Route path="/rx"  component={Rx} exact/>
    <Route path="/pdf"  component={PdfDoc} exact/>
  </Switch>
)


export default Router