import React, { useEffect } from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import { blue } from '@material-ui/core/colors';

const INITIAL_STATE = {

};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    width: 500,
    height: 700
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: 500,
    height: 700
  },
  title: {
    color: 'blue',
    fontWeight: 700
  }
});


const PdfDoc =  props => (
  <Document style="width: 500; height: 700">
    <Page size="LETTER"  style={styles.page}>
      <View style={styles.section}>
          <Text style={styles.title}>Fecha:</Text>
          <Text>{String(props.data.date)}</Text>
          <Text style={styles.title}>Expediente:</Text>
          <Text>{String(props.data.fileid)}</Text>
          <Text style={styles.title}>Médico que remite:</Text>
          <Text>{String(props.data.reference_doctor)}</Text>
          <Text style={styles.title}>Email:</Text>
          <Text>{String(props.data.email)}</Text>
          <Text style={styles.title}>Dueño:</Text>
          <Text>{String(props.data.owner_name)}</Text>
          <Text style={styles.title}>Paciente:</Text>
          <Text>{String(props.data.patient_name)}</Text>
          <Text style={styles.title}>Especie:</Text>
          <Text>{String(props.data.specie)}</Text>
          <Text style={styles.title}>Raza:</Text>
          <Text>{String(props.data.breed)}</Text>
          <Text style={styles.title}>Genero:</Text>
          <Text>{String(props.data.gender)}</Text>
          <Text style={styles.title}>Edad:</Text>
          <Text>{String(props.data.age)}</Text>
          <Text style={styles.title}>Fecha reporte previo:</Text>
          <Text>{String(props.data.previous_report_date)}</Text>
          <Text style={styles.title}>Historia clinica:</Text>
          <Text>{String(props.data.clinic_history)}</Text>
          <Text style={styles.title}>Hallazgos:</Text>
          <Text>{String(props.data.findings)}</Text>
          <Text style={styles.title}>Interpretación:</Text>
          <Text>{String(props.data.interpretation)}</Text>
          <Text style={styles.title}>Comentarios:</Text>
          <Text>{String(props.data.comments)}</Text> 
      </View>
    </Page>
  </Document>
);

export default PdfDoc;