import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
//import RaisedButton from 'material-ui/RaisedButton';
//import TextField from 'material-ui/TextField';
//import DropDownMenu from 'material-ui/DropDownMenu';
//import MenuItem from 'material-ui/MenuItem';
import {BrowserRouter} from 'react-router-dom';
import Router from './Router'
import './App.css';
class App extends Component {
  constructor(props){
    super(props);
    this.state={
      loginPage:[],
    }
  }
  componentWillMount(){

  }
  render() {
    return (
          <div  className="App">
            <MuiThemeProvider>
                <div>

                <AppBar
                  title="CEVI"
                  />
                <BrowserRouter>
                  <section>
                    <Router/>
                  </section>
                </BrowserRouter>
                  </div>
            </MuiThemeProvider>
          </div>
    );
  }
}


export default App;
