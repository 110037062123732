import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

const Input =  props => (
  <Grid item xs={props.xs} sm={props.sm}>
    <FormControl className="size90">
      <TextField
        id={props.id}
        name={props.id}
        label={props.label}
        placeholder={props.placeholder}
        className="size100"
        multiline
        rows="6"
        variant="outlined"
        value={props.value}
        onChange={props.onChange}
      />
    </FormControl>
  </Grid>
);

export default Input;