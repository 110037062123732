import React, { useEffect } from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';

const INITIAL_STATE = {

};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    width: 500,
    height: 700
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    width: 500,
    height: 700
  }
});


function PdfDoc (props){


    useEffect(()=>{

    },[])

    return (
      <Document style="width: 500; height: 700">
        <Page size="LETTER"  style={styles.page}>
          <View style={styles.section}>
            <Text>Section #1</Text>
          </View>
    
        </Page>
      </Document>
    )

}

export default PdfDoc