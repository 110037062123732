import React, { useEffect, useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import TextField from '@material-ui/core/TextField';

import 'date-fns';
import Grid from '@material-ui/core/Grid';
//import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import Container from '@material-ui/core/Container';
//import Paper from '@material-ui/core/Paper';
import MenuItem from 'material-ui/MenuItem';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import PdfDoc  from "../components/PdfDoc.js";
import InputGrid from '../components/Input.js';
import TextAreaGrid from '../components/TextAreaGrid.js';


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));
//cevi
//FEgIkJTrBkFUPb9N



function Rx (props){
  const {
    date = new Date('2020-01-18T21:11:54'),
    fileid = '',
    reference_doctor = '',
    email='',
    owner_name='',
    patient_name='',
    specie='',
    breed='',
    gender='',
    age=1,
    previous_report_date=new Date('2020-08-18T21:11:54'),
    clinic_history='',
    findings='',
    interpretation='',
    comments='',
  } = props;

  const [state,setState] = useState({
    date,
    fileid,
    reference_doctor,
    email,
    owner_name,
    patient_name,
    specie,
    breed,
    gender,
    age,
    previous_report_date,
    clinic_history,
    findings,
    interpretation,
    comments,
  })

  const classes = useStyles();
  function handleClick(){
  }
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));
  const [selectedDate2, setSelectedDate2] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleSliderChange = (event, newValue) => {
    setState({...state,age:newValue});
  };
  const handleDatePickerChange= (event, newValue) => {
    //setState({...state,date:newValue});
  };
  const handleDateChange = (event, newValue) => {
    console.log(event)
    setState({...state,date:newValue});
  };
  const handlePrevDateChange = (event, newValue) => {
    setState({...state,previous_report_date:newValue});
  };
  const handleChange = (evt, newValue) => {
    const value = evt.target.value;
    setState({...state,[evt.target.name]: value});
  };


    useEffect(()=>{

    },[])

    function submitForm(event) {
      console.log(state);
    }
    
    return (
      <Container maxWidth="md">
        <form 
          noValidate
          autoComplete="off"
        >
                    <Grid container  spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <FormControl className="size90">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="date"
                                label="Fecha"
                                format="MM/dd/yyyy"
                                value={state.date}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                      <InputGrid
                        label="Expediente"
                        id="fileid"
                        value={state.fileid}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <InputGrid
                        label="Médico que remite"
                        id="reference_doctor"
                        value={state.reference_doctor}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <InputGrid
                        label="E-mail"
                        id="email"
                        value={state.email}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <InputGrid
                        label="Propietario"
                        id="owner_name"
                        value={state.owner_name}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <InputGrid
                        label="Paciente"
                        id="patient_name"
                        value={state.patient_name}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <InputGrid
                        label="Especie"
                        id="specie"
                        value={state.specie}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />                      
                      <InputGrid
                        label="Raza"
                        id="breed"
                        value={state.breed}
                        onChange={handleChange}
                        xs={12} sm={4}
                      />
                      <Grid item xs={12} sm={4}>
                        <FormControl className="size90">
                          <InputLabel id="gender-label">Sexo</InputLabel>
                          <Select
                            labelId="gender-label"
                            id="gender"
                          >
                            <MenuItem value={10}>Macho</MenuItem>
                            <MenuItem value={20}>Hembra</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography id="age" gutterBottom>
                          Edad {state.age} años
                        </Typography> 
                        <Slider
                        className="size90"
                        value={typeof state.age === 'number' ? state.age : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="age"
                        valueLabelDisplay="auto"
                        step={1}
                        min={0}
                        max={40}
                        /> 
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl className="size90">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}  className="size90">
                            <KeyboardDatePicker
                                margin="normal"
                                id="previous_report_date"
                                label="Fecha de reportes previos"
                                format="MM/dd/yyyy"
                                value={state.previous_report_date}
                                onChange={handlePrevDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                      <TextAreaGrid
                        label="Historia clínica o razón del estudio"
                        id="clinic_history"
                        value={state.clinic_history}
                        onChange={handleChange}
                        placeholder="Historia clínica o razón del estudio"
                        xs={12} sm={12}
                      />
                     <TextAreaGrid
                        label="Hallazgos radiográficos"
                        id="findings"
                        value={state.findings}
                        onChange={handleChange}
                        placeholder="Hallazgos radiográficos"
                        xs={12} sm={12}
                      />
                      <TextAreaGrid
                        label="Interpretación radiografica"
                        id="interpretation"
                        value={state.interpretation}
                        onChange={handleChange}
                        placeholder="Interpretación radiografica"
                        xs={12} sm={12}
                      />
                      <TextAreaGrid
                        label="Comentarios"
                        id="comments"
                        value={state.comments}
                        onChange={handleChange}
                        placeholder="Comentarios"
                        xs={12} sm={12}
                      />
                      <Grid item xs={12} sm={12}>
                        <PDFDownloadLink document={<PdfDoc data={state}/>} fileName="somename.pdf">
                          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
                        </PDFDownloadLink>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.button}
                          startIcon={<PDFIcon />}
                        >
                          Guardar
                        </Button>
                      </Grid>
                      
                    </Grid>
        </form>
      </Container>
    )

}

export default Rx
