import React, { useEffect } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';


const INITIAL_STATE = {

};


function Login (props){
    function handleClick(){
      props.history.push('/rx');
    }

    useEffect(()=>{

    },[])

    return (
      <div>
        <TextField
        floatingLabelText="E-mail"
        onChange = {(event,newValue) => this.setState({username:newValue})}
        />
        <br/>
        <TextField
          type="password"
          floatingLabelText="Contraseña"
          onChange = {(event,newValue) => this.setState({password:newValue})}
          />
        <br/>
        <RaisedButton label="Ingresar" primary={true} onClick={(event) => handleClick(event)}/>
      </div>
    )

}

export default Login
